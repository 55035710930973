.check-claim-page {
  .card-title {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    padding: 20px 15px;
  }
  .card.documents {
    height: calc(100vh - 230px);
    > .card-body {
      overflow: auto;
    }
    @media (max-width: 991.98px) {
      height: 400px;
    }
    .source.selected {
      box-shadow: 4px 4px 16px rgba(141, 138, 160, 0.4), -4px -4px 16px rgba(141, 138, 160, 0.4);
    }
  }
  [class$='-multiValue'] {
    background-color: rgb(244, 248, 254);
    border: 1px solid rgb(205, 220, 252);
    > div {
      color: rgb(3, 81, 240);
      font-size: 11px;
      font-weight: 500;
    }
  }
}
