.search-bar {
  display: flex;
  width: 100%;
  background: #eeeef2;
  justify-items: center;
  color: black;
  border-radius: 30px;
  border: 1px solid #c9c7d5;
  border-radius: 106px;
  min-height: 90px;
  > div:nth-of-type(1)::before {
    background: transparent;
  }
  .search-item {
    .desc,
    .desc-1 {
      display: flex;
      color: #8d8aa0;
      font:
        normal 400 16px/19px Usual,
        Poppins;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
    &.search {
      padding: 16px 24px 16px 32px;
      .desc {
        flex-wrap: wrap;
        gap: 2px;
      }
    }
    cursor: pointer;
    label {
      color: #212027;
      font:
        normal 600 16px/19px Usual,
        Poppins;
    }
    .dropdown-toggle {
      padding: 16px 24px 16px 32px;
    }
    p {
      color: #8d8aa0;
      font:
        normal 400 16px/19px Usual,
        Poppins;
      margin: 0px;
    }
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      font:
        normal 600 24px/29px Usual,
        Poppins;

      height: 55%;
      background: hsl(0 0% 90%);
    }
    .form-control {
      background: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    position: relative;
    .text-input {
      display: none;
    }
    &.active {
      &::before {
        background: transparent;
      }
      background: white;
      border-radius: 106px;
      .text-input {
        display: block;
      }
      .desc {
        display: none;
      }
      .terms {
        display: flex;
      }
    }
    .claim {
      background: #5d5fef;
      padding: 5px 5px 5px 5px;
      color: white;
      display: flex;
      align-items: center;
      width: fit-content;
      i {
        font-size: 20px;
      }
    }
    &:hover {
      &::before {
        background: transparent;
      }
      background: white;
      border-radius: 106px;
    }
  }
  .terms {
    display: none;
    gap: 2px;
    margin-left: 10px;
    flex-wrap: wrap;
    .claim {
      font-size: 12px;
      padding: 2px;
    }
    .claim i {
      font-size: 14px;
    }
  }
  .default-filters {
    width: calc(100% - 158px);
  }
  .extras-filters {
    display: flex;
    gap: 10px;
    padding: 16px 24px 16px 32px;
    .rounded-button {
      width: 46px;
      height: 46px;
      background-color: #5d5fef;
      display: flex;
      color: white;
      align-items: center;
      padding: 13px;
      border-radius: 26px;
      justify-content: center;
      font-size: 20px;
      &:hover {
        background-color: #3a3dec;
      }
      &.search.disabled {
        opacity: 0.7;
        pointer-events: none;
      }
      &.filled {
        &::after {
          content: '';
          background: #ff126d;
          width: 12px;
          height: 12px;
          border-radius: 10px;
          display: block;
          position: absolute;
          top: 3px;
          right: -1px;
        }
      }
    }
  }
  .custom-dropdown {
    /* White */
    background: #ffffff;
    /* elevation-strong */
    box-shadow:
      8px 8px 24px rgba(141, 138, 160, 0.15),
      -8px -8px 24px rgba(141, 138, 160, 0.15);
    border-radius: 16px;
    top: 80px !important;
    padding: 40px 40px 24px;
    max-width: calc(100vw - 360px);
    max-height: calc(100vh - 330px);
    overflow-x: auto;
    &.main {
      top: 100px !important;
    }
    .container {
      gap: 50px;
      max-width: 100%;
      max-height: 100%;
    }
    .filter-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
    .dropdown-header {
      font:
        normal 600 24px/29px Usual,
        Poppins;
      color: #212027;
      padding: 0;
    }
    .save-btn {
      padding: 8px 16px;
      background: #00e1bd;
      border-radius: 4px;
      color: #3a2d83;
      font:
        normal 600 14px/24px Usual,
        Poppins;
      width: 100%;
      text-align: center;
    }
    .input-container {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      label {
        font:
          normal 400 16px/19px Usual,
          Poppins;
        color: black;
        white-space: nowrap;
        text-transform: capitalize;
      }
      input {
        width: 16px;
        height: 16px;
      }
    }
  }
}
