.pages {
  a {
    padding: 8px 16px;
    border: 1px solid #5d5fef;
    color: #5d5fef;
    font: normal 600 14px/24px Usual, Poppins;
    &:first-of-type {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-of-type {
      border-radius: 0px 4px 4px 0px;
    }
    &.active,
    &:hover {
      background: #5d5fef;
      color: #fff;
    }
  }
}
.sub-pages {
  a {
    padding: 8px 0px;
    color: #8d8aa0;
    font: normal 400 16px/19px Usual, Poppins;
    &.active,
    &:hover {
      color: #5d5fef;
    }
  }
}

.source-loading {
  display: block;
  text-align: center;
  max-width: 414px;
  margin-top: 70px;
  .title {
    font: normal normal 600 18px/24px Usual, Poppins;
    color: #635cff;
    margin-bottom: 24px;
  }
  .title-ready {
    color: #01d5b3;
    font: normal normal 600 16px/24px Usual, Poppins;
  }
  .description {
    font: normal normal 600 16px/24px Usual, Poppins;
    color: #3a2d83;
    margin-bottom: 16px;
  }
  span {
    font: normal normal 400 14px/18px Usual, Poppins;
    color: #3a2d83;
  }
}
