.post {
  &.negative {
    .polarity {
      background-color: #e91e63;
    }
  }
  &.positive {
    .polarity {
      background-color: #009688;
    }
  }
  &.neutral {
    .polarity {
      background-color: #484848;
    }
  }
  &.selected {
    box-shadow: 4px 4px 4px 1px rgb(131 131 131 / 38%), 2px 2px 3px 0px rgb(177 177 177);
    .post-content {
      font-weight: 600;
    }
  }
  box-shadow: 4px 4px 16px rgb(141 138 160 / 10%), -4px -4px 16px rgb(141 138 160 / 10%);
  .title {
    color: #3a2d83;
    font-size: 15px;
    font-weight: 600;
  }
  .polarity {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 3px;
    left: 0px;
    top: 0px;
  }
  .time {
    font-weight: 600;
    font-size: 12px;
    color: #009688;
  }
  .chart-radio {
    button {
      font-size: 10px;
      padding: 3px 15px;
      color: #3a3a3a;
      border-color: #3a3a3a;
      font-weight: 600;
      &.active {
        background: #3f51b5;
        color: white;
      }
      &:hover {
        background: #3f51b5;
        color: white;
      }
    }
  }
  .analysis-btn {
    background: #3f51b5;
    color: white;
    padding: 4px 10px;
    &:hover {
      background: #5968bc;
    }
  }
}
.post-analysis {
  padding: 5px 12px;
  box-shadow: 1px 1px 4px rgb(141 138 160 / 10%), -1px -1px 4px rgb(141 138 160 / 10%);
}
