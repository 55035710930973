.posts-container {
  overflow: auto;
  height: 726px;
  .header {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 3;
  }
  .filter {
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
    }
  }
}
.users-container {
  overflow: auto;
  height: 489px;
  .header {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 4;
  }
  .users-container-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .user {
    .title {
      color: #3d3d3d;
      font-size: 13px;
      font-weight: 600;
    }
    p {
      color: #747474;
    }
    .time {
      font-size: 11px;
      font-weight: 600;
      color: #009688;
    }
  }
}
.circle-tooltip {
  .tooltip-inner {
    text-align: start;
    border: 1px solid #480083;
    background: white;
    max-width: 100%;
    padding: 0px;
    .title {
      color: #3d3d3d;
      font-size: 13px;
      font-weight: 600;
    }
    p {
      color: #747474;
    }
  }
  .tooltip-arrow::before {
    border-top-color: #480083 !important;
  }
}

.t-behavior {
  .tooltip-arrow::before {
    border-top-color: #1b61f1 !important;
  }
}

.search-select {
  width: fit-content;
  z-index: 5;
  margin-bottom: 10px;
  [class$='control'] {
    border: 1px solid #ced4da;
    padding: 3px 3px 3px 40px;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    border-radius: 30px;
    width: 320px;
  }
}

.count-badge {
  background-color: $buster-blue;
  height: fit-content;
}

.Chart__wrapper {
  overflow-x: auto;
}

.pagination {
  .page-item.disabled {
    opacity: 0.6;
  }
  a.page-link {
    padding: 6px 8px;
    color: #6a677b;
    font-weight: 500;
    &:hover {
      color: #3a2d83;
    }
  }
}

.drawer {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 0;
  height: calc(100% - 140px);
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
  max-width: 0;
}

.drawer.open {
  max-width: 50%;
  width: 50%;
  z-index: 1;
  height: 100%;
  max-height: calc(100% - 5px);
  overflow: auto;
}
