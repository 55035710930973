.source {
  .polarity {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 5px;
    border-radius: 4px 0 0 4px;
    left: 0px;
    top: 0px;
    &.REFUTES,
    &.REFUTED {
      background-color: #ff126d;
    }
    &.CONFIRMS,
    &.CONFIRMED {
      background-color: #00e1bd;
    }
    &.DISCUSSES {
      background-color: #d1cfd5;
    }
  }
  box-shadow: 4px 4px 16px rgb(141 138 160 / 10%), -4px -4px 16px rgb(141 138 160 / 10%);
  a {
    color: #1d1d20;
  }
  .org {
    color: #454351;
    font-size: 12px;
    font-weight: 600;
  }
  .time {
    font-weight: 600;
    font-size: 12px;
    color: #009688;
  }
}
