.error {
  color: #ff126d;
  font-weight: 600;
}
.duration-metric {
  font-size: 12px;
  font-weight: 700;
}

.topics {
  background-color: rgb(244, 248, 254);
  border: 1px solid rgb(205, 220, 252);
  color: rgb(3, 81, 240);
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
}

.urls {
  background-color: rgba(174, 174, 174, 0.2);
  border: 1px solid rgb(147, 147, 147);
  color: rgb(96, 96, 96);
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }

  /* UNSPECIFIED */
  &.unspecified,
  &.unknown {
    background-color: rgba(174, 174, 174, 0.2);
    border: 1px solid rgb(147, 147, 147);
    color: rgb(96, 96, 96);
  }

  /* HARMLESS */
  &.harmless {
    background-color: rgba(0, 255, 0, 0.05);
    border: 1px solid rgb(6, 191, 139);
    color: rgb(6, 191, 139);
  }

  /* SUSPICIOUS */
  &.suspicious {
    background-color: rgba(255, 165, 0, 0.11);
    border: 1px solid rgb(255, 165, 0);
    color: rgb(255, 165, 0);
  }

  /* MALICIOUS */
  &.malicious {
    background-color: rgba(255, 158, 158, 0.17);
    border: 1px solid rgb(255, 65, 65);
    color: rgb(255, 65, 65);
  }
}

.toxicity {
  background-color: rgb(254, 244, 244);
  border: 1px solid rgb(252, 205, 205);
  color: rgba(240, 3, 3, 0.76);
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
}
