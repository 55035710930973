.dashboard-page {
  .entities-table .table > :not(caption) > * > * {
    padding: 8px 8px;
  }
  .searchbar {
    background-color: transparent;
  }
  .toxicity-count {
    gap: 5px;
    align-items: center;
    color: rgba(240, 3, 3, 0.76);
    font-weight: 500;
  }
  .apex-charts .apexcharts-datalabel {
    fill: white;
  }
  .search-select [class$='control'] {
    border: 1px solid #ced4da;
    padding: 1px 1px 1px 30px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    width: 320px;
  }
}
