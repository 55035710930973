@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
        span {
          position: fixed;
          z-index: 999;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

/*  tui charts**/
.tui-chart .tui-chart-chartExportMenu-area {
  z-index: 1 !important;
}

// message error of user form
.message-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
}

/* search bar **/
.searchbar {
  top: $header-height;
  right: 0;
  left: $sidebar-width;
  z-index: 4;
  background-color: $body-bg;
  padding: 2 calc(#{$grid-gutter-width} / 2) 2 0;
  transition: all 0.25s ease-in-out;

  @media (max-width: 991.98px) {
    left: 0;
    .form-control {
      max-width: 90vw;
    }
    .source-type {
      margin-top: 5px;
    }
  }

  .form-control {
    border: 1px solid $gray-400;
    height: 44px;
    width: 600px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: $white;
    color: $black;
    box-shadow: $box-shadow-sm;
    border-radius: 30px;
  }

  .form-control:hover,
  .form-control:focus {
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.125);
  }

  span {
    position: absolute;
    z-index: 10;
    font-size: 17px;
    line-height: 44px;
    left: 13px;
    top: 0;
    color: #556ee6;
  }
  .start,
  .stop {
    cursor: pointer;
  }

  &.nav-sticky {
    background-color: $topnav-bg;
    box-shadow: $box-shadow-lg;

    .form-control {
      height: 34px;
      border: 1px solid $gray-500;
      border-radius: 16px;
      box-shadow: none;
    }

    .form-control:hover,
    .form-control:focus {
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.125);
    }

    span {
      position: absolute;
      font-size: 16px;
      line-height: 34px;
      left: 11px;
    }
  }
}

/* set search bar left position when hiding side bar **/
.vertical-collpsed {
  .searchbar {
    left: $sidebar-collapsed-width;
    @media (max-width: 991.98px) {
      left: 0;
    }
  }
}

/* smooth transitions when hiding side bar **/
.vertical-menu {
  transition: all 0.25s ease-in-out;
}

#page-topbar {
  transition: all 0.25s ease-in-out;
}

.main-content {
  transition: all 0.25s ease-in-out;
}

// player buttons
.player-btn {
  text-align: center;
  color: $gray-500;
  box-shadow: none !important;
  border: 0;
  border-radius: 0px;
  position: relative;

  i {
    display: block;
    font-size: 24px;
    color: $gray-600;
    transition: all 0.25s;
  }

  &:hover {
    i {
      color: $primary;
      transform: scale(1.5);
    }
  }

  &.playing {
    i {
      color: $primary;
      transform: scale(1.5);
    }
  }

  &.zoom {
    i {
      color: $primary;
      transform: scale(1.5);
    }
  }
}

/** 
 * Video close button
 */
.close-btn {
  text-align: center;
  box-shadow: none !important;
  border: 0;
  border-radius: 0px;
  position: absolute;
  left: 94%;

  i {
    color: $white;
    display: block;
    font-size: 20px;
    transition: all 0.25s;
  }

  &:hover {
    i {
      transform: scale(1.25);
    }
  }
}

/** 
 * Bruch
 */
.brush-x .overlay {
  &:hover {
    cursor: pointer;
  }
}

/** 
 * User form
 */
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/**
 * UsersTable CSS sort icon 
 * 
 * react-bootstrap-table/react-bootstrap-table2 - BoostrapTable
 * Issues: Sorting icon not showing - https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/577
 */
.dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 4px dashed;
}
.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.info-user-profile {
  width: 40%;
}

.pencil-edit-usercard {
  width: 40%;
}

.user-profile-icon-pencile {
  padding-left: 60%;
}

.last-login-usercard {
  padding-top: 5%;
}

.mdi-key-variant::before {
  content: '\F030B';
}

.icon-eye {
  .textInput {
    padding-right: 70px;
  }

  span {
    position: absolute;
    font-size: 24px;
    line-height: 44px;
    right: 35px;
    top: 15%;
  }
}

.div-edit-avatar {
  text-align: center;

  .avatar-profil {
    height: 135px;
    width: 135px;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .dot {
    position: absolute;
    top: 38%;
    left: 52%;
    height: 30px;
    width: 30px;
    z-index: 10;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: large;
  }
}

.fixed-top {
  z-index: 4;
}

.position-fixed {
  position: fixed;
}

/**
 * css for datepicker
 *
*/
.date-label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.date-input {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.delete-fileupload {
  padding-left: 100%;
}

.buttonCollapse {
  margin-bottom: 1rem;
}

.max-width-20 {
  max-width: 20%;
}

.width-80 {
  width: 80%;
}

.invalid > div {
  border-color: #f46a6a;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f46a6a%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f46a6a%27 stroke=%27none%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.99em + 0.99rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.invalid > div:hover {
  border-color: #f46a6a;
}

.category-field > div {
  box-shadow: 0 0 0 1px #ced4da;
}

.category-field-hover > div:hover {
  border-color: #ced4da;
}

.icon-title {
  font-size: 26px;
}

.intro-form-uploading-textbox {
  background-color: rgb(135, 136, 146);
  border-color: rgb(135, 136, 146);
}

.input-link {
  width: 78%;
  height: 10%;
}

.button-search {
  width: 21.5%;
}

.preview-video {
  width: 660px;
}

.navbar-brand-box {
  text-align: left;
}
