.articles-cards {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 20px;
  row-gap: 20px;
  .article-card {
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 24px 10px 32px;
    // gap: 24px;
    width: 95%;
    background: #ffffff;
    border: 1px solid #f4f3f6;
    box-shadow: 4px 4px 16px rgba(141, 138, 160, 0.1), -4px -4px 16px rgba(141, 138, 160, 0.1);
    border-radius: 16px;
    .date {
      font: normal 700 14px/16px Usual, Poppins;
      color: #6a677b;
    }
    .title {
      font: normal 600 20px/27px Usual, Poppins;
      color: #3a2d83;
    }
    .passage {
      font: normal 400 16px/23px Usual, Poppins;
      color: #242424;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .score {
      position: absolute;
      bottom: 5px;
      left: 25px;
      color: #009688;
      font-weight: 500;
    }
    // .more {
    //   padding: 8px 16px;
    //   gap: 4px;
    //   border: 1px solid #5d5fef;
    //   border-radius: 4px;
    // }
  }
}
.source-loading {
  display: block;
  text-align: center;
  max-width: 414px;
  margin-top: 70px;
  .title {
    font: normal normal 600 18px/24px Usual, Poppins;
    color: #635cff;
    margin-bottom: 24px;
  }
  .title-ready {
    color: #01d5b3;
    font: normal normal 600 16px/24px Usual, Poppins;
  }
  .description {
    font: normal normal 600 16px/24px Usual, Poppins;
    color: #3a2d83;
    margin-bottom: 16px;
  }
  span {
    font: normal normal 400 14px/18px Usual, Poppins;
    color: #3a2d83;
  }
}
