.filter-button {
  button {
    color: #6a677b;
    background: transparent;
    font-weight: 600;
    &:hover {
      background: white;
    }
  }
}
.postsFilters {
  .filterContainer {
    border: none;
    background: #fff;
    min-width: 350px;
    .accordion-button:not(.collapsed) {
      background-color: #fff;
      .filterHeader {
        color: #635cff;
        font-weight: 600;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
  .collapse > div {
    box-shadow: 4px 4px 8px rgba(141, 138, 160, 0.05), -4px -4px 8px rgba(141, 138, 160, 0.05);
    border: 1px solid #f4f3f6;
    border-radius: 10px;
  }
  .accordion-button {
    padding: 5px 10px;
  }
  .options-box {
    border-radius: 4px;
    box-shadow: 4px 4px 8px rgba(141, 138, 160, 0.05), -4px -4px 8px rgba(141, 138, 160, 0.05);
    padding: 4px;
  }
  hr {
    margin: 5px 0px;
  }
  .filterHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #454351;
    font: normal 400 12px/17px Usual;
    min-width: 260px;
    &:hover {
      font: normal 600 12px/17px Usual;
    }
  }
  .filterOptions {
    white-space: normal;
    max-width: 300px;
    border: 1px solid #f4f3f6;
    border-radius: 4px;
    margin-top: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      padding: 0px 4px;
    }
    label {
      margin: 0px;
      text-transform: capitalize;
    }
    .count {
      color: #6a677b;
      font: normal normal 400 12px/16px Usual;
      padding: 0px 8px;
    }
    &.sort {
      border: 0;
      font: normal normal 400 13px/20px Usual;
      padding: 4px 12px;
      &:hover {
        border-radius: 0;
      }
      &.Mui-selected {
        font-weight: 600;
      }
    }
    &:hover {
      background-color: #efefff;
    }
  }
  .selectAll {
    border: 0;
    padding: 6px;
    color: #6a677b;
    font: normal normal 600 12px/17px Usual;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      font: normal normal 600 12px/17px Usual;
    }
  }
  .accordion-button::after {
    width: 12px;
    height: 12px;
    background-size: 12px;
  }
}
